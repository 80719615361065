var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"size":"lg","hide-footer":""},on:{"show":_vm.init_component},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [[_vm._v(" "+_vm._s(_vm.$t("monte.signature_contrat"))+" ")]]},proxy:true}])},[(_vm.loading)?_c('LoadingSpinner',{staticClass:"col-12"}):[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"contact"}},[_vm._v(_vm._s(_vm.$t("monte.contact"))+" *")]),_c('e-select',{attrs:{"id":"contact","track-by":"contact_id","label":"contact_label","placeholder":_vm.$t('horse.search_contact_tiers'),"options":_vm.all_contacts,"searchable":true},model:{value:(_vm.contact_selected),callback:function ($$v) {_vm.contact_selected=$$v},expression:"contact_selected"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"type_validation"}},[_vm._v(_vm._s(_vm.$t("monte.type_validation.title")))]),_c('e-select',{attrs:{"id":"type_validation","placeholder":_vm.$t('monte.select_type_validation'),"options":_vm.all_validation,"searchable":true,"show-labels":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t('monte.type_validation.'+option)))]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(_vm.$t('monte.type_validation.'+option)))]}}]),model:{value:(_vm.validation_selected),callback:function ($$v) {_vm.validation_selected=$$v},expression:"validation_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"users"}},[_vm._v(_vm._s(_vm.$t("monte.user_to_notify"))+" *")]),_c('e-select',{attrs:{"id":"users","placeholder":_vm.$t('monte.select_user_to_notify'),"options":_vm.all_users,"searchable":true,"show-labels":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.firstname)+" "+_vm._s(option.lastname)+" ("+_vm._s(option.email)+")")]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.firstname)+" "+_vm._s(option.lastname)+" ("+_vm._s(option.email)+")")]}}]),model:{value:(_vm.user_selected),callback:function ($$v) {_vm.user_selected=$$v},expression:"user_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"date_expiration"}},[_vm._v(_vm._s(_vm.$t("monte.date_expiration")))]),_c('e-datepicker',{attrs:{"id":"date_expiration"},model:{value:(_vm.date_expiration),callback:function ($$v) {_vm.date_expiration=$$v},expression:"date_expiration"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"modeltype_label"}},[_vm._v(_vm._s(_vm.$t('monte.modele_envoi'))+" *")]),_c('e-select',{attrs:{"id":"model_id","track-by":"model_label","label":"model_label","placeholder":_vm.$t('monte.select_modele'),"options":_vm.templates,"searchable":true,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.model_label))]}}]),model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"form-group mt-4"},[_c('label',{attrs:{"for":"mailtype_label"}},[_vm._v(_vm._s(_vm.$t('model.expeditor_label'))+" *")]),_c('e-select',{attrs:{"disabled":_vm.nomail,"id":"expeditor_id","placeholder":_vm.$t('model.search_expeditor'),"options":_vm.finalExpeditors,"searchable":true,"allow-empty":false,"show-labels":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option))]}}]),model:{value:(_vm.finalExpeditor),callback:function ($$v) {_vm.finalExpeditor=$$v},expression:"finalExpeditor"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"commentaire"}},[_vm._v(_vm._s(_vm.$t("monte.commentaire")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.commentaire),expression:"commentaire"}],staticClass:"form-control",attrs:{"id":"commentaire","rows":"3"},domProps:{"value":(_vm.commentaire)},on:{"input":function($event){if($event.target.composing){ return; }_vm.commentaire=$event.target.value}}})]),(_vm.show_error_champ)?_c('ErrorAlert',{attrs:{"messageI18n":'formulaire.erreur_champs_non_remplis'}}):_vm._e(),(_vm.show_error_validation)?_c('ErrorAlert',{attrs:{"messageI18n":'monte.signature_validation_erreur'}}):_vm._e(),_c('div',{staticClass:"col"},[_c('b-button',{staticClass:"mt-3 btn-block rounded-pill",attrs:{"variant":"primary"},on:{"click":_vm.checkForm}},[_vm._v(_vm._s(_vm.$t("action.envoyer"))+" "),(_vm.processing)?_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'spinner'],"pulse":""}}):_vm._e()],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }