<template>
	<b-modal ref="modal" size="lg" hide-footer @show="init_component">
		<template v-slot:modal-title>
			<template>
				{{ $t("monte.signature_contrat") }}
			</template>
		</template>

		<LoadingSpinner v-if="loading" class="col-12" />

		<!-- <div v-else-if="signature_link">
			<e-copybox boxed :value="signature_link"></e-copybox>
		</div> -->

		<template v-else>
			<div class="form-group">
				<label for="contact" class="col-form-label">{{ $t("monte.contact") }} *</label>
				<e-select
					id="contact"
					track-by="contact_id"
					label="contact_label"
					:placeholder="$t('horse.search_contact_tiers')"
					:options="all_contacts"
					:searchable="true"
					v-model="contact_selected"
				/>
			</div>

			<div class="form-group">
				<label for="type_validation" class="col-form-label">{{ $t("monte.type_validation.title") }}</label>
				<e-select
					id="type_validation"
					:placeholder="$t('monte.select_type_validation')"
					:options="all_validation"
					:searchable="true"
					:show-labels="false"
					v-model="validation_selected"
				>
					<template slot="option" slot-scope="{ option }">{{ $t('monte.type_validation.'+option) }}</template>
					<template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.type_validation.'+option) }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="form-group">
				<label for="users" class="col-form-label">{{ $t("monte.user_to_notify") }} *</label>
				<e-select
					id="users"
					:placeholder="$t('monte.select_user_to_notify')"
					:options="all_users"
					:searchable="true"
					:show-labels="false"
					v-model="user_selected"
				>
					<template slot="option" slot-scope="{ option }">{{ option.firstname }} {{ option.lastname }} ({{ option.email }})</template>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.firstname }} {{ option.lastname }} ({{ option.email }})</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="form-group">
				<label for="date_expiration" class="col-form-label">{{ $t("monte.date_expiration") }}</label>
				<e-datepicker id="date_expiration" v-model="date_expiration"></e-datepicker>
			</div>

			<div class="form-group">
				<label for="modeltype_label">{{ $t('monte.modele_envoi') }} *</label>
				<e-select
					v-model="template"
					id="model_id"
					track-by="model_label"
					label="model_label"
					:placeholder="$t('monte.select_modele')"
					:options="templates"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="form-group mt-4">
				<label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
				<e-select
					v-model="finalExpeditor"
					:disabled="nomail"
					id="expeditor_id"
					:placeholder="$t('model.search_expeditor')"
					:options="finalExpeditors"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"        
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="form-group">
				<label for="commentaire" class="col-form-label">{{ $t("monte.commentaire") }}</label>
				<textarea id="commentaire" class="form-control" rows="3" v-model="commentaire"></textarea>
			</div>

			<ErrorAlert v-if="show_error_champ" :messageI18n="'formulaire.erreur_champs_non_remplis'" />
			<ErrorAlert v-if="show_error_validation" :messageI18n="'monte.signature_validation_erreur'" />

			<div class="col">
				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
import Contact from '@/mixins/Contact.js'
import Contract from '@/mixins/Contract.js'
import Model from "@/mixins/Model.js"
import User from '@/mixins/User.js'

export default {
	name: "ModalSignatureContract",
	mixins: [Contact, Contract, Model, User],
	props: ['contract'],
	data () {
		return {
			loading: false,
			processing: false,
			all_contacts: [],
			all_validation: ['none', 'sms', 'email'],
			all_users: [],
			contact_selected: null,
			validation_selected: 'none',
			user_selected: null,
			date_expiration: null,
			signature_link: '',
			show_error_champ: false,
			show_error_validation: false,
			templates: [],
			template: null,
			finalExpeditors: [],
			finalExpeditor: null,
			nomail: false,
			commentaire: ''
		}
	}, 
	methods: {
		async init_component() {
			this.loading = true

			const contacts = await this.getContactByTiers(this.contract.tiers.tiers_id)

			this.all_contacts = contacts.filter(contact => {
				return contact.contact.contact_mail !== ''
			})
			.map(contact => ({
				contact_id: contact.contact.contact_id,
				contact_label: contact.contact.contact_firstname + ' ' + contact.contact.contact_lastname,
				contact_phone: contact.contact.phone_combine,
				contact_mail: contact.contact.contact_mail
			}))

			this.all_users = await this.getUserLicence()

			this.templates = await this.loadAllModel(0, ['contract_envoie_mail', 'contract_envoi_mail_invoice'])

			const fromAddress = await this.loadAllFromAddress()
			await this.FormatFromAddress(fromAddress)

			this.loading = false
		},

		openModal() {
			this.contact_selected = null
			this.validation_selected = 'none'
			this.user_selected = null
			this.date_expiration = null
			this.signature_link = ''

			this.$refs.modal.show()
		},

		async FormatFromAddress(fromAddress){
			let array_mail = []
			let array_name = []
			this.finalExpeditors = []
			const selected = fromAddress["selected"]
			delete fromAddress["selected"]

			for (const [key, value] of Object.entries(fromAddress)){				
				if (value.licenceconfig_key === "from_address"){
					if (value.licenceconfig_value != ''){
						const configValueAddress = value.licenceconfig_value.split(',')
						for (const address of configValueAddress){
							array_mail.push(address.trim())
						}
					}
				}
				if (value.licenceconfig_key === "from_name"){
					if (value.licenceconfig_value != ''){
						const configValueName = value.licenceconfig_value.split(',')
						for (const name of configValueName){
							array_name.push(name.trim())
						}
					}
				}
				if(array_mail.length > 0 && array_name.length > 0){
					if(array_name[0] === 'Groomy'){
						this.nomail = true
					}
					break;
				}
			}

			for (const index in array_mail){
				if(array_mail[index] === selected["email"]){
					this.finalExpeditors.unshift(array_mail[index]+ " / " + array_name[index])
				} else {
					this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
				}
			}

			this.finalExpeditor = this.finalExpeditors[0]
		},

		async generateSignatureLink() {
			this.processing = true
			const params = {
				contact_id: this.contact_selected.contact_id,
				type_validation: this.validation_selected,
				user_email: this.user_selected.email,
				date_expiration: this.date_expiration,
				model_id: this.template.model_id,
				commentaire: this.commentaire,
				expeditor: this.finalExpeditor
			}
			const res = await this.generateContractSignatureLink(this.contract.contract_id, params)
			if(res == 'ok') {
				this.successToast('monte.toast_signature_success')
			}
			this.processing = false

			this.$refs.modal.hide()
		},

		async checkForm() {
			this.show_error_champ = false
			this.show_error_validation = false

			if(this.contact_selected == null || this.user_selected == null || this.template == null) {
				this.show_error_champ = true
				return false
			}
			
			if((this.validation_selected == 'sms' && !this.contact_selected.contact_phone) || (this.validation_selected == 'email' && !this.contact_selected.contact_mail)) {
				this.show_error_validation = true
				return false
			}

			this.generateSignatureLink()
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
	}
}
</script>